import _ from "lodash";
import Inputmask from "inputmask";
import utils from "../utils";

let flyer_1 = async function () {

    let pizzas = [
        {
            name: 'Баварская',
            title: 'Пицца Баварская',
            image: '/assets/pizza/pizza_1.png',
            svg: '<g transform="matrix(1 0 0 1 0 -3.814697e-06)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2275" height="1882" id="pizza_1" xlink:href="{{xImage}}" transform="matrix(0.1362 9.523457e-03 -9.523457e-03 0.1362 212.2472 -44.8212)"></image></g>'
        },
        {
            name: 'Пепперони',
            title: 'Пицца Пепперони',
            image: '/assets/pizza/pizza_2.png',
            svg: '<g transform="matrix(1 0 -7.450581e-09 1 0 -1.907349e-06)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2292" height="2067" id="pizza_2" xlink:href="{{xImage}}" transform="matrix(0.1126 7.873332e-03 -7.961993e-03 0.1139 207.4073 -21.2598)"></image></g>'
        },
        {
            name: 'Спайси Чоризо',
            title: 'Пицца Спайси Чоризо',
            image: '/assets/pizza/pizza_3.png',
            svg: '<g transform="matrix(1 1.490116e-08 0 1 0 -3.814697e-06)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2178" height="2069" id="pizza_3" xlink:href="{{xImage}}" transform="matrix(0.113 0.025 -0.0263 0.1187 237.2168 -42.6722)"></image></g>'
        },
        {
            name: 'Супермясная',
            title: 'Пицца Супермясная',
            image: '/assets/pizza/pizza_4.png',
            svg: '<g transform="matrix(1 0 -7.450581e-09 1 0 0)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2221" height="1991" id="pizza_4" xlink:href="{{xImage}}" transform="matrix(0.1116 0.0122 -0.0131 0.1202 224.3368 -29.5885)"></image></g>'
        },
        {
            name: 'Трюфельная Пепперони',
            title: 'Трюфельная Пепперони',
            image: '/assets/pizza/pizza_5.png',
            svg: '<g transform="matrix(1 -1.490116e-08 1.490116e-08 1 0 -3.814697e-06)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2093" height="2035" id="pizza_5" xlink:href="{{xImage}}" transform="matrix(0.1182 0.0202 -0.0202 0.1182 228.6206 -22.0241)"></image></g>'
        },
        {
            name: 'Цыпленок Ранч',
            title: 'Пицца Цыпленок Ранч',
            image: '/assets/pizza/pizza_6.png',
            svg: '<g transform="matrix(1 0 0 1 0 0)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2282" height="2072" id="pizza_6" xlink:href="{{xImage}}" transform="matrix(0.1181 8.261367e-03 -8.261367e-03 0.1181 209.2332 -25.2275)"></image></g>'
        },
        {
            name: 'Четыре Сыра',
            title: 'Пицца Четыре Сыра',
            image: '/assets/pizza/pizza_7.png',
            svg: '<g transform="matrix(1 7.450581e-09 0 1 0 0)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2330" height="2072" id="pizza_7" xlink:href="{{xImage}}" transform="matrix(0.1133 7.919949e-03 -8.117047e-03 0.1161 212.9902 -29.6811)"></image></g>'
        },
        {
            name: 'Чиз Карбонара',
            title: 'Пицца Чиз Карбонара',
            image: '/assets/pizza/pizza_8.png',
            svg: '<g transform="matrix(1 0 0 1 0 3.814697e-06)" style="clip-path:url(#SVGID_00000131335465766833374460000001089468957205425037_);"><image style="overflow:visible;" width="2352" height="2146" id="pizza_8" xlink:href="{{xImage}}" transform="matrix(0.1147 8.018038e-03 -7.949867e-03 0.1137 210.2156 -23.2716)"></image></g>'
        }
    ];




    // COMPANY
    const $company = $('.field.company input');
    const savedCompany = utils.getFromLS('company');
    $company.on('input', function () {
        $('#xCompany').html($(this).val());
        utils.setToLS('company', $(this).val());
    });
    if(savedCompany){
        $company.val(savedCompany);
        $('#xCompany').html(savedCompany);
    }

    // ADDRESS
    const $address = $('.field.address input');
    const savedAddress = utils.getFromLS('address');
    $address.on('input', function () {
        $('#xAddress').html($(this).val());
        utils.setToLS('address', $(this).val());
    });
    if(savedAddress){
        $address.val(savedAddress);
        $('#xAddress').html(savedAddress);
    }

    // PHONE
    const $phone = $('.field.phone input');
    const savedPhone = utils.getFromLS('phone');
    $phone.on('input', function () {
        $('#xPhone').html($(this).val());
        utils.setToLS('phone', $(this).val());
    });
    const phoneMaskFormat = '+7 999 999 99 99';
    Inputmask({
        mask: phoneMaskFormat,
        showMaskOnHover: false,
        clearMaskOnLostFocus: false,
        onKeyValidation: function () {
        },
        onBeforePaste: function (pastedValue, opts) {
            if (Array.from(pastedValue)[0] === '8') {
                return pastedValue.substring(1);
            } else if (Array.from(pastedValue)[0] === '+') {
                return pastedValue.substring(2);
            } else {
                return pastedValue;
            }
        }
    }).mask($phone[0]);
    if(savedPhone){
        $phone.val(savedPhone);
        $('#xPhone').html(savedPhone);
    }


    // UNTIL
    const $until = $('.field.until input');
    const savedUntil = utils.getFromLS('until');
    $until.on('input', function () {
        $('#xUntil').html($(this).val());
        utils.setToLS('until', $(this).val());
    });
    if(savedUntil){
        $until.val(savedUntil);
        $('#xUntil').html(savedUntil);
    }


    // BACKGROUND
    const $background = $('.field.background .radio');
    const savedBackground = utils.getFromLS('background');
    const changeBg = function(id){
        const colors = {
            bgGreen: [
                '#00A757',
                '#FF8C88'
            ],
            bgPink: [
                '#FF8C88',
                '#FFFFFF'
            ]
        };

        const color = colors[id];
        $('.xFillBG').css('fill', color[0]);
        $('.xFillPromo').css('fill', color[1]);
    };
    $background.on('change', function () {
        const checked = $(this).find('input:checked').attr('id');
        utils.setToLS('background', checked);
        changeBg(checked);
    });
    if(savedBackground){
        $('#'+savedBackground).prop("checked", true);
        changeBg(savedBackground);
    }


    // SCHEDULE
    const $scheduleDays = $('.field.scheduleDays input');
    const $scheduleHours = $('.field.scheduleHours input');
    const $schedule = $('.field.schedule .checkbox');
    const savedScheduleDays = utils.getFromLS('scheduleDays');
    const savedScheduleHours = utils.getFromLS('scheduleHours');
    const savedSchedule = utils.getFromLS('schedule');
    $scheduleDays.on('input', function () {
        $('#xDays').html($(this).val());
        utils.setToLS('scheduleDays', $(this).val());
    });
    $scheduleHours.on('input', function () {
        $('#xHours').html($(this).val());
        utils.setToLS('scheduleHours', $(this).val());
    });
    $schedule.on('change', function () {
        if ($(this).is(':checked')) {
            $('#xSchedule').css('visibility', 'visible');
        } else {
            $('#xSchedule').css('visibility', 'hidden');
        }
        utils.setToLS('schedule', $(this).is(':checked'));
    });
    if(savedScheduleDays){
        $scheduleDays.val(savedScheduleDays);
        $('#xDays').html(savedScheduleDays);
    }
    if(savedScheduleHours){
        $scheduleHours.val(savedScheduleHours);
        $('#xHours').html(savedScheduleHours);
    }
    if(savedSchedule !== null){
        console.log('some');
        console.log(savedSchedule);
        if (savedSchedule === 'true') {
            $schedule.prop("checked", true);
            $('#xSchedule').css('visibility', 'visible');
        } else {
            $schedule.prop("checked", false);
            $('#xSchedule').css('visibility', 'hidden');
        }
    }

    // DELIVERY
    const $delivery = $('.field.delivery .radio');
    const savedDelivery = utils.getFromLS('delivery');
    const changeDelivery = function(id){
        const options = {
            deliveryFull: '«Доставка или Самовывоз»',
            deliverySimple: '«Доставка»'
        };
        const option = options[id];
        $('#xDelivery').html(option);
    };
    $delivery.on('change', function () {
        const checked = $(this).find('input:checked').attr('id');
        utils.setToLS('delivery', checked);
        changeDelivery(checked);
    });
    if(savedDelivery){
        $('#'+savedDelivery).prop("checked", true);
        changeDelivery(savedDelivery);
    }


    // PROMOCODE
    const savedPromocode = utils.getFromLS('promocode');
    const updatePromocode = (val) => {
        const $bg = $('#xPromoCode');
        const $group = $('#xPromoGroup');
        const padding = 4 + 5;
        $bg.html(val);
        const bgWidth = $group[0].getBBox().width + padding;
        $('#xPromoBG').attr('width', bgWidth);

    };
    const $promoode = $('.field.promocode input');
    $promoode.on('input', function () {
        let val = $(this).val();
        utils.setToLS('promocode', val);
        updatePromocode(val);
    });
    if(savedPromocode){
        setTimeout(()=>{
            $promoode.val(savedPromocode);
            updatePromocode(savedPromocode)
        }, 100)
    }


    // PIZZA
    const toggleLoading = function(state){
        if(state) {
            $('.loading').addClass('active');
            $('.target').addClass('fade');
            $('.button.save').addClass('fade');
        }else{
            $('.loading').removeClass('active');
            $('.target').removeClass('fade');
            $('.button.save').removeClass('fade');
        }
    };
    const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        }))
    const selectPizza = async (index) => {
        let $dropdown = $('.field.pizza .dropdown');
        let pizza = pizzas[index];
        $dropdown.text(pizza.name);

        toggleLoading(true);
        const img = await toDataURL(pizza.image);

        $('#xTitle1').html(pizza.title.toUpperCase());
        $('#xImage').html(pizza.svg.replace('{{xImage}}', img));
        toggleLoading(false);
    };
    _.each(pizzas, (pizza)=>{
        $('.field.pizza .dropdown-options').append('<li>' + pizza.name + '</li>');
    })
    $('.field.pizza .dropdown').on('click', function () {
        $(this).find('+.dropdown-options').toggleClass('active');
    });
    $('.field.pizza .dropdown-options li').on('click', async function () {
        $(this).parent().removeClass('active');
        utils.setToLS('pizza', $(this).index());
        await selectPizza($(this).index())
    });
    if(utils.getFromLS('pizza')){
        await selectPizza(utils.getFromLS('pizza'));
    }
    // await selectPizza(7);




};

export default flyer_1;