import flyer_1 from "./templates/flyer_1";
import flyer_2 from "./templates/flyer_2";
import axios from "axios";
import FileSaver from 'file-saver';

let generator = async function () {

    let flyerToLoad = 'flyer_1'
    let isGenerating = false;

    let flyerFunctions = {
        flyer_1: flyer_1,
        flyer_2: flyer_2
    };

    const toggleLoading = function(state){
        if(state) {
            $('.loading').addClass('active');
            $('.target').addClass('fade');
            $('.button.save').addClass('fade');
        }else{
            $('.loading').removeClass('active');
            $('.target').removeClass('fade');
            $('.button.save').removeClass('fade');
        }
    };

    let loadFlyer = function (flyer){
        toggleLoading(true);
        fetch('/pages/templates/'+flyer+'.html')
            .then(response => response.text())
            .then(text => {
                $('.settings').html(text);
                fetch('/assets/'+flyer+'.svg')
                    .then(response => response.text())
                    .then(text => {
                        $('.target').html(text);
                        flyerFunctions[flyer]();
                        toggleLoading(false);
                    });
            });
    };

    loadFlyer(flyerToLoad);

    $('.templates .template').on('click', function(){
        $('.templates .template').removeClass('active');
        $(this).addClass('active');
        flyerToLoad = $(this).attr('id');
        loadFlyer(flyerToLoad);
    });


    $('.button.save').on('click', function(){
        if(!isGenerating) {
            const self = $(this);
            isGenerating = true;
            console.log('saving...');
            self.addClass('sending');

            let svgData = $("#target")[0].outerHTML;

            axios.post('/api/image/save', {
                blob: svgData
            }, {
                responseType: 'arraybuffer'
            })
                .then(function (response) {
                    self.removeClass('sending');
                    isGenerating = false;
                    const blob = new Blob([response.data], {type: 'image/tiff'});
                    FileSaver.saveAs(blob, flyerToLoad + '.tiff');

                })
                .catch(function (error) {
                    console.log(error);
                    isGenerating = false;
                    self.removeClass('sending');
                });

        }
    });

};

export default generator;