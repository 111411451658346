import './scss/app.sass';

window.$ = require('jquery');
import Navigo from 'navigo';

import generator from './generator';

window.addEventListener("load", () => {
    const router = new Navigo("/");
    const pagesDir = '/pages/';

    let render = function (page, script = false, params = {}) {
        fetch(pagesDir + page + '.html')
            .then(response => response.text())
            .then(text => {
                $('#content').html(text);
                if (script) {
                    script(params.data);
                }

            });
    };

    router
        // .on("/", function () {
        //     render('home', home);
        // })
        // .on("/flyer/:flyerId", function (params) {
        //     render('flyer', flyer, params);
        // })
        .on("/", function () {
            render('generator', generator);
        })
        .notFound(() => {
            render('404');
        })
        .resolve();
});



