import _ from "lodash";
import utils from "../utils";
import Inputmask from "inputmask";

let flyer_2 = async function () {

    // DISCOUNT
    const $discount = $('.field.discount input');
    const savedDiscount = utils.getFromLS('discount2');
    $discount.on('input', function () {
        $('#xDiscount').html($(this).val());
        utils.setToLS('discount2', $(this).val());
    });
    if(savedDiscount){
        $discount.val(savedDiscount);
        $('#xDiscount').html(savedDiscount);
    }

    // PHONE
    const $phone = $('.field.phone input');
    const savedPhone = utils.getFromLS('phone2');
    $phone.on('input', function () {
        $('#xPhone').html($(this).val());
        utils.setToLS('phone2', $(this).val());
    });
    if(savedPhone){
        $phone.val(savedPhone);
        $('#xPhone').html(savedPhone);
    }

    // SCHEDULE
    const $scheduleHours = $('.field.scheduleHours input');
    const savedScheduleHours = utils.getFromLS('scheduleHours2');
    $scheduleHours.on('input', function () {
        $('#xHours').html($(this).val());
        utils.setToLS('scheduleHours2', $(this).val());
    });
    if(savedScheduleHours){
        $scheduleHours.val(savedScheduleHours);
        $('#xHours').html(savedScheduleHours);
    }

    // ADDRESS
    const $address = $('.field.address input');
    const savedAddress = utils.getFromLS('address2');
    $address.on('input', function () {
        $('#xAddress').html($(this).val());
        utils.setToLS('address2', $(this).val());
    });
    if(savedAddress){
        $address.val(savedAddress);
        $('#xAddress').html(savedAddress);
    }
};

export default flyer_2;